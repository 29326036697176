<template>
  <h1>
    <span>Операция: {{title}} ({{cnt}})</span>
    <div v-if="ext_fields.datecontrol" class="shift-forward">
      <span>Посмотреть на </span>
      <input @change="recalcAndSaveDate"
             type="number" min="0" max="100" v-model="shift_forward_day" />
      <span> {{shift_forward_day % 10 == 1 ? ' день' :
        shift_forward_day % 10 == 2 || shift_forward_day % 10 == 3
        || shift_forward_day % 10 == 4 ? ' дня' : ' дней'}} в будущее</span>
    </div>
  </h1>

  <div class="row-flex" >
    <div class="row-flex--item head" style="flex:2">Приоритет</div>
    <div class="row-flex--item head" style="flex:3">Заказ</div>
    <div v-if="ext_fields.paper" class="row-flex--item head" style="flex:2">Наличие бумаги</div>
    <div v-if="ext_fields.operation" class="row-flex--item head" style="flex:6">Операция</div>
    <div v-if="ext_fields.adjustment" class="row-flex--item head"
         style="flex:2">Зак. на прилад.</div>
    <div v-if="ext_fields.pontoon" class="row-flex--item head"
         style="flex:6">Пантон</div>
  </div>
  <div class="row-flex" v-for="(order, index) in orders" :key="index">
    <div class="row-flex--item" style="flex:2" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
      'red': order.statusBuff >= 0 && order.statusBuff < 33,
      'black': order.statusBuff < 0,
      }">{{order.priority}}</div>
    <div class="row-flex--item" style="flex:3" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
      'red': order.statusBuff >= 0 && order.statusBuff < 33,
      'black': order.statusBuff < 0,
      }">{{order.number}}</div>
    <div v-if="ext_fields.paper" class="row-flex--item" style="flex:2" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
      'red': order.statusBuff >= 0 && order.statusBuff < 33,
      'black': order.statusBuff < 0,
      }">{{order.withPaper}}</div>
    <div v-if="ext_fields.operation" class="row-flex--item" style="flex:6" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
      'red': order.statusBuff >= 0 && order.statusBuff < 33,
      'black': order.statusBuff < 0,
      }">{{order.operation}}</div>
    <div v-if="ext_fields.adjustment" class="row-flex--item" style="flex:2" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
      'red': order.statusBuff >= 0 && order.statusBuff < 33,
      'black': order.statusBuff < 0,
      }">{{order.flag_cust_for_adj == 1 ? 'Да' : 'Нет' }}</div>
    <div v-if="ext_fields.pontoon" class="row-flex--item" style="flex:6" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
      'red': order.statusBuff >= 0 && order.statusBuff < 33,
      'black': order.statusBuff < 0,
      }">{{order.flug_cust_for_pontoon }}</div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'OperationDetail',
  data() {
    return {
      error: [],
      title: '',
      orders: [],
      cnt: 0,
      ext_fields: {
        paper: false,
        operation: false,
        adjustment: false,
        pontoon: false,
        datecontrol: false,
      },

      shift_forward_day: 0,
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    getPageInfo() {
      const str = '/api/page-operation-test?name_operation=';
      axios.get(str + this.$route.params.code)
        .then((res) => {
          if (res.data.success === 1) {
            this.title = res.data.page_info.title;
            this.orders = res.data.page_info.orders;
            this.cnt = res.data.cnt;
            this.ext_fields.paper = res.data.paper;
            this.ext_fields.operation = res.data.operation;
            this.ext_fields.adjustment = res.data.adjustment;
            this.ext_fields.pontoon = res.data.pontoon;
            this.ext_fields.datecontrol = res.data.datecontrol;

            this.shift_forward_day = res.data.page_info.shift_forward_day;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    recalcAndSaveDate() {
      axios.post('/api/chg-shift-date-trick-paper', { shift_days: this.shift_forward_day })
        .then((res) => {
          if (res.data.status === 1) {
            this.getPageInfo();
          } else {
            alert('ошибка очистки дат');
          }
          this.loader = false;
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
  },
};
</script>

<style scoped>
.row-flex{display: flex; padding:2px 10px; margin-left:-10px; margin-right:-10px;}
.row-flex--item{flex: 1; padding:2px 10px; font-size:40px; line-height:50px;}
.row-flex--item.head{background:#3e5977; color:#eee; font-size:32px; line-height:44px;
  padding-bottom:8px;}
.row-flex--item.right{text-align:right}

.row-flex--item.black{background: #14171a; color: #eee;}
.row-flex--item.red{background: #e33838; color: #eee;}
.row-flex--item.yellow{background: #f2f251; color: #000;}
.row-flex--item.green{background: #2e842e; color: #eee;}

.shift-forward{
  font-size: 16px;
  line-height: 18px;
  display:inline-block;
  margin-left:12px;
  vertical-align: middle;
}
.shift-forward input{height: 30px;
  width: 70px;}

.icon-right{float:right; cursor:pointer}

.wrp-calendar{
  position: fixed;
  left: 0px;
  top: 0px;
  margin: 0px auto;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  background: #000000ab;
  min-height: 100%;}
</style>
